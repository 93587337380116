

import {NgModule,OnInit} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';
import {AppCustomPreloader} from './appCustomPreloader';

import {AuthGuardfor} from './service/authGuard.service';
export const routes: Routes = [
    
      {
        path: '',
        loadChildren:'./login/login.module#LoginModule',
      },
     

      {path:'userVerification/:id',loadChildren:'../app/userVerification/userVerifaction.module#UserVerificationModule'},
     
      {path:'resetpassword',loadChildren:'../app/resetpassword/resetpassword.module#ResetPasswordModule'},
      {path: 'sso-auth', loadChildren:'./sso-auth/sso-auth.module#SSOAuthModule'},
    ];


@NgModule({
    imports: [
      RouterModule.forRoot(routes, {useHash: true,preloadingStrategy: AppCustomPreloader}),
    ],
  
    exports: [RouterModule],
    providers: [AppCustomPreloader],
    declarations: []
  })
export class AppRoutesModule implements OnInit
{
   
    constructor() 
    {

    }
    ngOnInit() 
    {

    history.pushState(null, null,location.href);
    window.onpopstate = function(event) 
    {
    window.history.go(1);
    
    };
  
    window.menubar.visible ;
    window.toolbar.visible  ;
    
    }
}


