import {Injectable,OnInit,EventEmitter} from '@angular/core';
import {HttpClientService} from './httpClient.service';
import { CookieService } from 'ngx-cookie-service';
import {LoginService} from '../service/login.service';
import {ApiListService} from '../service/apiList.service';
import {MdliveService} from '../service/mdliveApi.service';
import {StatesService} from '../service/states.service'
declare var $:any;
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Injectable({
    providedIn:'root'
})
export class ServicePageService 
{
    public custom: EventEmitter<string>;mdlive;
    
    constructor( private httpClient:HttpClientService,private login:LoginService,private api:ApiListService,private states:StatesService,
        private cookie:CookieService,private mdliveS:MdliveService, private spinner:NgxSpinnerService, private _service: NotificationsService, 
        private router:Router) {
        try{this.mdlive =JSON.parse(this.cookie.get('mdlive'))}catch(ex){}
        this.custom = new EventEmitter();
        
    }
    emit_custom_event(for_rec) {
        this.custom.emit(for_rec);
    }

    hidecomp(value,id) {

        if(value==0)
        {
            $("#"+id).css({display:'none'})
        }
        else
        {
            $("#"+id).css({display:'block'})
        }
    }

    masterDropDown(url) {
        return this.httpClient.get(url);
    }
    getStateList(url) {
        return this.httpClient.get(url);
    }
    getCityfromStateId(url) {
        return this.httpClient.get(url);
    }
    opportunityCost(url) {
        return this.httpClient.get(url);
    }
    saveErrorLog(url,obj)
    {
        return this.httpClient.post(url,obj);
    }
    confirmationRating(url,obj)
    {
        return this.httpClient.post(url,obj);
    }
    extendJwt() {

        this.cookie.deleteAll();
        localStorage.clear();
        setTimeout(() => {
            this.router.navigate(['/']);
        },5000);
  
    }

    family_members=[];pharmacy;ProviderType:any=[];ProfliCompleat;passesnt_id=null
     count=0;

    patients(id) {
        this.count=0;
        this.spinner.show();
        this.family_members=[];

        if(id != undefined || id != null) {
            var url = this.api.patient+id;
            this.mdliveS.getpatients(url).subscribe(
              sucesses=>{
                setTimeout(() => {
                    this.spinner.hide();
                }, 2000);
                  this.count=0;
    
                  if(sucesses.patient_profile.unread_message_count != 0 && this.router.url != '/home/servicePage') {
                    this.states.messageCount.total_notification_count = 0;
                    this.states.messageCount.total_notification_count += this.states.messageCount.customer_support_notification_count
                    this.states.messageCount.total_notification_count += sucesses.patient_profile.unread_message_count;
                }
    
                var key=Object.keys(sucesses.patient_profile);
                 key.map((obj)=>{
                    if( sucesses.patient_profile[obj]== null || sucesses.patient_profile[obj].length==0){
                        this.count++
                    }
                })
                this.ProfliCompleat=Math.round((key.length-this.count)/key.length *100);
                if(this.ProfliCompleat<0){
                    this.ProfliCompleat=0;
                }
                this.pharmacy=sucesses.patient_profile.pharmacy;
                this.family_members=sucesses.patient_profile.family_members;
                this.ProviderType=sucesses.patient_profile.provider_types;
                this.cookie.set('providerTypeIncookie',JSON.stringify(this.ProviderType));
                      
                localStorage.setItem('allProviderTypes',encodeURIComponent(JSON.stringify(sucesses.patient_profile.provider_types)));
                
    
                localStorage.setItem('patientProfile',JSON.stringify(sucesses.patient_profile));
    
            },error=>{
                setTimeout(() => {
                    this.spinner.hide();
                }, 2000);
                if(error.status == 401){
                    this._service.error('error', this.states.sessionExpiredMessage);
                  this.extendJwt()
                }
                
              })
        }else{
            setTimeout(() => {
                this.spinner.hide();
            }, 2000);
        }

        
    }

    patientsFullSwitch(id) {
        this.count=0;
        // this.spinner.show();
        this.family_members=[];

        if(id != undefined || id != null) {
            var url = this.api.patient+id;
            this.mdliveS.getpatients(url).subscribe(
              sucesses=>{
                setTimeout(() => {
                    this.spinner.hide();
                }, 2000);
                  this.count=0;
    
                  if(sucesses.patient_profile.unread_message_count != 0 && this.router.url != '/home/servicePage') {
                    this.states.messageCount.total_notification_count = 0;
                    this.states.messageCount.total_notification_count += this.states.messageCount.customer_support_notification_count
                    this.states.messageCount.total_notification_count += sucesses.patient_profile.unread_message_count;
                }
    
                var key=Object.keys(sucesses.patient_profile);
                 key.map((obj)=>{
                    if( sucesses.patient_profile[obj]== null || sucesses.patient_profile[obj].length==0){
                        this.count++
                    }
                })
                this.ProfliCompleat=Math.round((key.length-this.count)/key.length *100);
                if(this.ProfliCompleat<0){
                    this.ProfliCompleat=0;
                }
                this.pharmacy=sucesses.patient_profile.pharmacy;
                this.family_members=sucesses.patient_profile.family_members;
                this.ProviderType=sucesses.patient_profile.provider_types;
                this.cookie.set('providerTypeIncookie',JSON.stringify(this.ProviderType));
                      
                localStorage.setItem('allProviderTypes',encodeURIComponent(JSON.stringify(sucesses.patient_profile.provider_types)));
                
    
                localStorage.setItem('patientProfile',JSON.stringify(sucesses.patient_profile));
    
            },error=>{
                setTimeout(() => {
                    this.spinner.hide();
                }, 2000);
                if(error.status == 401){
                    this._service.error('error', this.states.sessionExpiredMessage);
                  this.extendJwt()
                }
                
              })
        }else{
            setTimeout(() => {
                this.spinner.hide();
            }, 2000);
        }

        
    }


    currentPharmacy=false;
    getCurrentPharmacy() {
        var url = this.api.documents_v1 +this.mdlive.m_patient_id+'/pharmacies/current_pharmacy';
        this.mdliveS.getpatients(url).subscribe(
        sucesses=>{
            this.currentPharmacy=true;
            this.pharmacy=sucesses.pharmacy;
            if (this.pharmacy !=null) 
            {
                this.currentPharmacy=true;
            }
            else
            {
                this.currentPharmacy=false;
            }

        },error=>{
            if(error.status == 401){
                this._service.error('error', this.states.sessionExpiredMessage);
                this.extendJwt();
              }
        });

    }


    sendEmailAgent(url){
        return this.httpClient.get(url)
    }

    getTileList(url){
        return this.httpClient.get(url)
    }

    getSingleTileList(url){
        return this.httpClient.get(url)
    }
    getCallMethod(url){
        return this.httpClient.get(url)
    }
}


  
    
   











