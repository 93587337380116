import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSOAuthRoutingModule } from './sso-auth-routing.module';
import { SSOAuthComponent } from './sso-auth.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    SSOAuthRoutingModule
  ],
  declarations: [SSOAuthComponent]
})
export class SSOAuthModule { }
