import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiListService } from '../service/apiList.service';
import { ServicePageService } from '../service/servicePage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'angular2-notifications';
import { StatesService } from '../service/states.service';
import { CookieService } from 'ngx-cookie-service';
import { Ng2DeviceService } from 'ng2-device-detector';

@Component({
  selector: 'src-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: []
})
export class SSOAuthComponent implements OnInit {

  token: string | null = null;
  parentLogin: any;


  constructor(private route: ActivatedRoute, private router: Router, private api: ApiListService, private servicePageService: ServicePageService,
    private spinner: NgxSpinnerService, private _service: NotificationsService, public states: StatesService, private cookie: CookieService,
    private deviceService: Ng2DeviceService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    JSON.stringify(this.deviceService.getDeviceInfo())
    this.cookie.set('deviceInfo', JSON.stringify(this.deviceService.getDeviceInfo()))

    this.route.queryParamMap.subscribe((params) => {
      this.token = params.get('token'); // Retrieve token from URL
      this.parentLogin = params.get('is_parent_login');
      if (this.token) {
        this.cookie.set('token', this.token);
        this.cookie.set('primaryToken', this.token);
        this.cookie.set('isParentLogin', this.parentLogin);
        this.getProfile()
      }
    });
  }

  dependentsToSwitch: any = []
  getProfile() {
    this.spinner.show();
    var url = this.api.userProfile;
    this.servicePageService.masterDropDown(url).subscribe(data => {
      localStorage.setItem("lyricData", JSON.stringify(data.primary.lyric_data))
      this.getDepentdentToSwitch(data)
      localStorage.setItem("dp23", JSON.stringify(data.primary))
      localStorage.setItem("dependentsToSwitch23", JSON.stringify(this.dependentsToSwitch))
      localStorage.setItem("data23", JSON.stringify(data))
      this.router.navigate([`/home`]);

    },
      error => {
        this.spinner.hide();
        if (error.status == 401) {
          this._service.error('Error', this.states.sessionExpiredMessage);
          this.servicePageService.extendJwt()
        }
      });
  }

  getDepentdentToSwitch(data) {

    this.dependentsToSwitch = []
    let localdata = localStorage.getItem('primaryUser')
    if ((data.dependents == null || data.dependents.length == 0) && (!data.primary.is_primary)) {
      this.dependentsToSwitch.push(data.parent);

    }

    else {
      for (var i = 0; i < data.dependents.length; i++) {
        if (data.dependents[i].is_adult_dependent) {
          if (data.dependents[i].is_registered && (data.dependents[i].permission_allow_to_primary)) {
            this.dependentsToSwitch.push(data.dependents[i]);
          }
        } else if (data.dependents[i].is_registered && !data.dependents[i].is_adult_dependent) {
          this.dependentsToSwitch.push(data.dependents[i]);
        }
      }
    }
  }

}
