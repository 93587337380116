import {Injectable,OnInit} from '@angular/core';
import {HttpClientService} from './httpClient.service';
import {Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {ApiListService} from './apiList.service';

@Injectable({
    providedIn:'root'
})
export class LoginService  implements OnInit
{
    constructor(private httpClient:HttpClientService ,private router:Router,private cookie:CookieService, private apiList : ApiListService)
    {
        

    }
    ngOnInit() 
    {
     
     
    }
    
    postRegistration( url ,obj)
    {
       return this.httpClient.post(url,obj);
    }


    login(url,obj)
    {
        return this.httpClient.PostLogin(url,obj);
    }
    loginLanguage(url,obj)
    {
        return this.httpClient.PostLoginLanguage(url,obj);
    }
    logOut(url,obj){
        return this.httpClient.post(url,obj);
    }
    getUpdatedTokenMDlive(url){
        return this.httpClient.get(url);
    }

    setpassword(url,data){
        return this.httpClient.PostLogin(url,data)
    }

    emailVerify(params) {
        return this.httpClient.get(this.apiList.emailVerify + '?' + params);
    }
}